<template>
    <div class="detailPanel" :style="{'height':height+'px'}">
        <!-- <UserTaskDetail v-if="model.clazz" :model="model" :onChange="onChange" :readOnly="readOnly" :users="users" :groups="groups" /> -->
        <DefaultDetail :model="model" :onChange="onChange" :readOnly="readOnly"  :users="users" :groups="groups" />
    </div>
</template>
<script>
  import DefaultDetail from "./DefaultDetail"
  export default {
    inject: ['i18n'],
    components:{
      DefaultDetail,
    },
    props: {
      height: {
        type: Number,
        default: 800,
      },
      model: {
        type:Object,
        default: ()=>({}),
      },
      users: {
        type: Array,
        default: ()=>([]),
      },
      groups: {
        type: Array,
        default: ()=>([]),
      },
      onChange: {
        type: Function,
        default: ()=>{}
      },
      readOnly:{
        type: Boolean,
        default: false,
      }
    },
  }
</script>
<style lang="scss">
    .detailPanel {
        height: 100%;
        background: #f0f2f5;
        flex: 0 0 auto;
        float: left;
        width: 20%;
        border-right: 1px solid #E9E9E9;
        border-bottom: 1px solid #E9E9E9;
        text-align: left;
        padding: 10px;
    }
</style>

export default {
  'label': 'Label',
  'hideIcon': 'Hide Icon',
  'userTask': 'User Task',
  'userTask.assignType': 'Assign Type',
  'userTask.assignType.placeholder': 'Select a assign type',
  'userTask.assignType.assignee': 'Assignee',
  'userTask.assignType.assignee.title': 'Assignee',
  'userTask.assignType.assignee.placeholder': 'Select Assignee',
  'userTask.assignType.person': 'Candidate Users',
  'userTask.assignType.person.title': 'Candidate Users',
  'userTask.assignType.person.placeholder': 'Select Candidate Users',
  'userTask.assignType.persongroup': 'Candidate Groups',
  'userTask.assignType.persongroup.title': 'Candidate Groups',
  'userTask.assignType.persongroup.placeholder': 'Select Candidate Groups',
  'userTask.dueDate': 'Due Date',
  'userTask.dueDate.placeholder': 'Select date',
  'userTask.counterSign': 'CounterSign',
  'scriptTask': 'Script Task',
  'scriptTask.script': 'Script',
  'javaTask': 'Java Task',
  'javaTask.javaClass': 'Java Class Name',
  'mailTask': 'Mail Task',
  'mailTask.to': 'To',
  'mailTask.subject': 'Subject',
  'mailTask.content': 'Content',
  'receiveTask': 'Receive Task',
  'receiveTask.waitState': 'Wait State',
  'receiveTask.stateValue': 'State Value',
  'timerEvent': 'Timer Event',
  'timerEvent.cycle': 'Cycle',
  'timerEvent.cycle.placeholder': 'Select time',
  'timerEvent.duration': 'Duration',
  'messageEvent': 'Message Event',
  'messageEvent.message': 'Message',
  'signalEvent': 'Signal Event',
  'signalEvent.signal': 'Signal',
  'sequenceFlow': 'Sequence Flow',
  'sequenceFlow.expression': 'Expression',
  'sequenceFlow.seq': 'Sequence',
  'sequenceFlow.reverse': 'Reverse',
  'startEvent': 'Start Event',
  'endEvent': 'End Event',
  'start': 'Start Events',
  'end': 'End Events',
  'gateway': 'Gateway',
  'exclusiveGateway': 'Exclusive Gateway',
  'parallelGateway': 'Parallel Gateway',
  'inclusiveGateway': 'Inclusive Gateway',
  'task': 'Task',
  'catch': 'Catching Event',
  'tooltip.undo': 'Undo',
  'tooltip.redo': 'Redo',
  'tooltip.copy': 'Copy',
  'tooltip.paste': 'Paste',
  'tooltip.delete': 'Delete',
  'tooltip.zoomIn': 'Zoom In',
  'tooltip.zoomOut': 'Zoom Out',
  'tooltip.zoomReset': 'Zoom Reset',
  'tooltip.autoFit': 'Auto Fit',
  'tooltip.toFront': 'To Front Layer',
  'tooltip.toBack': 'To Back Layer',
  'tooltip.edit': 'Edit',
  'process': 'Workflow',
  'process.category': 'Category',
  'process.id': 'Workflow ID',
  'process.name': 'Workflow Name',
  'process.dataObjs': 'Data Objects',
  'process.signalDefs': 'Signal Defs',
  'process.messageDefs': 'Message Defs',
  'process.dataObjs.id': 'Id',
  'process.dataObjs.name': 'Name',
  'process.dataObjs.type': 'Type',
  'process.dataObjs.defaultValue': 'DefaultValue',
  'process.signalDef.scope': 'Scope',
};

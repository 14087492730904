<template>
  <div class="root">
    <div style="display: block">
      <ItemPanel ref="addItemPanel" v-if="!isView" :height="height" />
      <div ref="canvas" class="canvasPanel" :style="{'height':height+'px','width':!isView?'60%':'100%','border-bottom':isView?0:null}"></div>
      <DetailPanel ref="detailPanel" v-if="!isView&&(selectedModel.id||'').length>0" :height="height" :model="selectedModel" :readOnly="mode !== 'edit'" :users="users" :groups="groups" :onChange="(key,val)=>{onItemCfgChange(key,val)}" />
    </div>
  </div>
</template>
<script>
import G6 from '@antv/g6/lib';
import { getShapeName } from '@/components/workflow/util/clazz.js'

import Command from '@/components/workflow/plugins/command'
import AddItemPanel from '@/components/workflow/plugins/addItemPanel'
import CanvasPanel from '@/components/workflow/plugins/canvasPanel'

import ItemPanel from '@/components/workflow/ItemPanel'
import i18n from '@/components/workflow/locales'

import DetailPanel from '@/components/workflow/DetailPanel'

import { exportXML, exportImg } from "@/components/workflow/util/bpmn"
import registerShape from '@/components/workflow/shape'
import registerBehavior from '@/components/workflow/behavior'
registerShape(G6);
registerBehavior(G6);
export default {
  name: "flowDraw",
  components: {
    ItemPanel,
    DetailPanel
  },
  provide() {
    return {
      i18n: i18n[this.lang]
    }
  },
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "edit"
    },
    height: {
      type: Number,
      default: 800,
    },
    width: {
      type: Number,
      default: 900,
    },
    lang: {
      type: String,
      default: "zh"
    },
    data: {
      type: Object,
      default: () => ({ nodes: [], edges: [] })
    },
    users: {
      type: Array,
      default: () => ([])
    },
    groups: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      resizeFunc: () => { },
      selectedModel: {},
      processModel: {
        id: '',
        name: '',
        category: '',
        clazz: 'process',
      },
      graph: null,
      cmdPlugin: null,
    };
  },
  watch: {
    data(newData, oldData) {
      if (oldData !== newData) {
        if (this.graph) {
          // this.graph.changeData(this.initShape(newData));
          // this.graph.setMode(this.mode);
          // this.graph.emit('canvas:click');
          this.graph.data(this.initShape(newData));
          this.graph.render();
          if (this.cmdPlugin) {
            this.cmdPlugin.initPlugin(this.graph);
          }
          if (this.isView) {
            this.graph.fitView(5)
          }
        }
      }
    },

  },
  methods: {
    initShape(data) {
      if (data && data.nodes) {
        return {
          nodes: data.nodes.map(node => {
            return {
              shape: getShapeName(node.clazz),
              ...node,
            }
          }),
          edges: data.edges
        }
      }
      return data;
    },
    initEvents() {
      this.graph.on('afteritemselected', (items) => {
        if (items && items.length > 0) {
          let item = this.graph.findById(items[0]);
          if (!item) {
            item = this.getNodeInSubProcess(items[0])
          }
          this.selectedModel = { ...item.getModel() };
        } else {
          this.selectedModel = this.processModel;
        }
      });
      const page = this.$refs['canvas'];
      const graph = this.graph;
      const height = this.height - 1;
      this.resizeFunc = () => {
        graph.changeSize(page.offsetWidth, height);
      };
      window.addEventListener("resize", this.resizeFunc);
    },
    onItemCfgChange(key, value) {
      const items = this.graph.get('selectedItems');
      if (items && items.length > 0) {
        let item = this.graph.findById(items[0]);
        if (!item) {
          item = this.getNodeInSubProcess(items[0])
        }
        if (this.graph.executeCommand) {
          this.graph.executeCommand('update', {
            itemId: items[0],
            updateModel: { [key]: value }
          });
        } else {
          this.graph.updateItem(item, { [key]: value });
        }
        this.selectedModel = { ...item.getModel() };
      } else {
        const canvasModel = { ...this.processModel, [key]: value };
        this.selectedModel = canvasModel;
        this.processModel = canvasModel;
      }
    },
    getNodeInSubProcess(itemId) {
      const subProcess = this.graph.find('node', (node) => {
        if (node.get('model')) {
          const clazz = node.get('model').clazz;
          if (clazz === 'subProcess') {
            const containerGroup = node.getContainer();
            const subGroup = containerGroup.subGroup;
            const item = subGroup.findById(itemId);
            return subGroup.contain(item);
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
      if (subProcess) {
        const group = subProcess.getContainer();
        return group.getItem(subProcess, itemId);
      }
      return null;
    },
  },
  unmounted() {
    window.removeEventListener("resize", this.resizeFunc);
    this.graph.getNodes().forEach(node => {
      node.getKeyShape().stopAnimate();
    });
  },
  mounted() {
    let plugins = [];
    if (!this.isView) {
      this.cmdPlugin = new Command();
      const addItemPanel = new AddItemPanel({ container: this.$refs['addItemPanel'].$el });
      const canvasPanel = new CanvasPanel({ container: this.$refs['canvas'] });
      plugins = [this.cmdPlugin, addItemPanel, canvasPanel];
    }
    if (!this.width)
      this.width = this.$refs['canvas'].offsetWidth;
    //const autoWidth = this.$refs['canvas'].offsetWidth;
    this.graph = new G6.Graph({
      plugins: plugins,
      container: this.$refs['canvas'],
      height: this.height,
      width: this.width,
      modes: {
        default: ['drag-canvas', 'clickSelected'],
        view: [],
        edit: ['drag-canvas', 'hoverNodeActived', 'hoverAnchorActived', 'dragNode', 'dragEdge',
          'dragPanelItemAddNode', 'clickSelected', 'deleteItem', 'itemAlign', 'dragPoint', 'brush-select'],
      },
      defaultEdge: {
        shape: 'flow-polyline-round',
      },
    });
    this.graph.saveXML = (createFile = true) => exportXML(this.graph.save(), this.processModel, createFile);
    this.graph.saveImg = (createFile = true) => exportImg(this.$refs['canvas'], this.processModel.name, createFile);
    if (this.isView)
      this.graph.setMode('view');
    else
      this.graph.setMode(this.mode);
    this.graph.data(this.initShape(this.data));
    this.graph.render();
    if (this.isView && this.data && this.data.nodes) {
      this.graph.fitView(5)
    }
    this.initEvents();
  }
};
</script>
<style lang="scss" scoped>
.root {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: block;
}
.canvasPanel {
  flex: 0 0 auto;
  float: left;
  max-width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #e9e9e9;
}
</style>

<template>
  <el-form ref="forms" label-width="80">
    <el-form-item :label="i18n['label']" prop="label">
      <el-input v-model="model.label" :disabled="readOnly" :value="model.label" autocomplete="off" minlength="1"
        maxlength="10" @input="(value) => { onChange('label', value) }">
      </el-input>
    </el-form-item>
    <el-form-item :label="i18n['hideIcon']" prop="hideIcon">
      <el-switch @change="(value) => onChange('hideIcon', value)" :disabled="readOnly" :value="!!model.hideIcon" />
    </el-form-item>
    <el-form-item label="固定用户审核" prop="operatorName">
      <el-autocomplete style="width:120px;" v-model="operatorName" :fetch-suggestions="searchAsync" placeholder="选择审批人"
        @select="chooseHandle">
      </el-autocomplete>

      <div class="personBox">
        <el-tag closable v-for="item in model.operator" :key="item" @close="closeHandle(item)">
          {{ item.realName }}
        </el-tag>
      </div>
    </el-form-item>
  </el-form>
</template>
<script>
import { fetchUsers } from "@/api/sys";
export default {
  inject: ['i18n'],
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
    onChange: {
      type: Function,
      default: () => { }
    },
    readOnly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      operatorName: '',
    }
  },
  methods: {
    //搜索（请求后台数据）
    searchAsync(queryString, callback) {
      //console.log(queryString, callback);
      let search = {
        page: 1,
        rows: 10,
        columns: [
          { prop: "realName", searchable: true },
          { prop: "gender" },
        ],
        filters: [],
        keyword: queryString,
      };
      search.filters.push({ name: "isEnabled", value: '1', operate: 0, });
      fetchUsers(search).then((res) => {
        let inputOptions = [];
        res.data.data.forEach(function (item) {
          inputOptions.push({
            value: item.realName,
            ...item,
          });
        });

        callback(inputOptions);
      });
    },
    //选择选中事件
    chooseHandle(item) {
      console.log(item);

      this.model.operator = this.model.operator || [];
      if (this.model.operator.filter(res => res.id == item.id).length == 0) {
        //this.forms.operatorId = item.id;
        //this.forms.operatorName = item.realName;
        this.model.operator.push(item);
      }

      this.onChange('operator', this.model.operator);
      this.operatorName = '';
    },
    closeHandle(item) {
      this.model.operator = this.model.operator.filter(res => res.id != item.id);
      this.onChange('operator', this.model.operator);
    },

  }
}
</script>
